@import url("https://fonts.googleapis.com/css2?family=Graduate&display=swap");
html {
  min-height: 100%;
  overflow-y: scroll;
}
body {
  min-height: 100%;
  font-family: "Graduate", cursive !important;
}

body {
  height: 100%;
  margin: 0;
  overflow: hidden;
}

.App {
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url("../src/assets/Rectangle\ 13.png"),
    url("../src/assets/mike-enerio-se4RWwpqAMk-unsplash\ 1.png"),
    url("../src/assets/erwan-hesry-WPTHZkA-M4I-unsplash\ 1.png");

  background-position: center;
  background-color: black;
  height: 100%;
}

.card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 150px;
}
h1 {
  font-weight: 500;
  font-size: 25px;
  color: white;
  padding: 10px;
  text-align: center;
}
p {
  color: white;
  font-size: 15px;
}
.card {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: space-between;
  width: 250px;
  height: 350px;
  border-width: 0px;
  border-style: solid;
  border-radius: 30px;
  box-shadow:
    0 0 4px #479977,
    inset 0 0 4px #0164f8,
    0 0 8px #1b74fa,
    inset 0 0 16px #479977,
    0 0 32px #0164f8,
    inset 0 0 32px #1b74fa;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    320deg,
    rgba(30, 30, 40, 0.95) 0%,
    rgba(39, 39, 47, 0.95) 35%
  );
  padding: 20px;
}
.card p {
  font-size: 20px;
}
.card > *:second-child {
  justify-content: center;
}
.form-container {
  display: flex;
  flex-direction: column;
  gap: 100px;
  align-items: center;
  margin-bottom: 100px;
}
form {
  margin-top: 50px;
  display: flex;
  gap: 50px;
  flex-direction: column;
}
.form-topline {
  display: flex;
  gap: 20px;
}
.form-input {
  display: flex;
  flex-direction: column;
}
h3 {
  font-weight: 100;
  color: white;
}
.button {
  cursor: pointer;
  font-size: 45px;
  color: white;
  box-shadow:
    0 0 4px #0164f8,
    inset 0 0 4px #0164f8,
    0 0 8px #1b74fa,
    inset 0 0 8px #0164f8,
    0 0 16px #0164f8,
    inset 0 0 16px #1b74fa;
  background: transparent;
  border-width: 0;
  padding: 20px 100px;
  border-radius: 20px;
}
.tel {
  width: 99%;
  padding: 0;
}
.input {
  height: 30px;
}
.popup {
  animation: fadeIn 1s;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  width: 500px;
  height: 200px;
  top: 50%;
  left: 50%;
  margin-top: -100px;
  margin-left: -250px;
  color: white;
  background-color: rgb(0, 0, 0);
  z-index: 999;
  border-radius: 20px;
  border-width: 0px;
  border-style: solid;
  border-radius: 30px;
  box-shadow:
    0 0 4px #479977,
    inset 0 0 4px #0164f8,
    0 0 8px #1b74fa,
    inset 0 0 16px #479977,
    0 0 32px #0164f8,
    inset 0 0 32px #1b74fa;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    320deg,
    rgba(30, 30, 40, 0.95) 0%,
    rgba(39, 39, 47, 0.95) 35%
  );
}
.popup-button {
  cursor: pointer;
  font-size: 25px;
  color: white;
  box-shadow:
    0 0 4px #0164f8,
    inset 0 0 4px #0164f8,
    0 0 8px #1b74fa,
    inset 0 0 8px #0164f8,
    0 0 16px #0164f8,
    inset 0 0 16px #1b74fa;
  background: transparent;
  border-width: 0;
  padding: 5px 20px;
}
@media only screen and (max-width: 600px) {
  h1 {
    font-weight: 400;
    font-size: 20px;
    color: white;
  }
  p {
    font-size: 10px;
    color: white;
  }
  .card {
    width: 200px;
    height: 320px;
    border-radius: 15px;
    box-shadow:
      0 0 4px #479977,
      inset 0 0 4px #0164f8,
      0 0 8px #1b74fa,
      inset 0 0 16px #479977,
      0 0 16px #0164f8,
      inset 0 0 16px #1b74fa;
    background: rgb(2, 0, 36);
    background: linear-gradient(
      320deg,
      rgba(30, 30, 40, 0.95) 0%,
      rgba(39, 39, 47, 0.95) 35%
    );
  }
  .card p {
    font-size: 15px;
  }
  header img {
    width: 100vw;
  }
  .card-container {
    flex-direction: column;
    gap: 50px;
  }
  .form-topline {
    gap: 10px;
    flex-direction: column;
    width: 100%;
  }
  form {
    gap: 10px;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .form-container {
    padding-left: 10px;
    padding-right: 10px;
    width: 80%;
  }
  .form-input {
    width: 100%;
  }
  .button {
    padding: 10px 25px;
    margin-top: 60px;
    font-size: 15px;
    width: 40%;
  }
  .popup {
    width: 300px;
    height: 300px;
    margin-top: -150px;
    margin-left: -150px;
    justify-content: space-around;
  }
  .popup p {
    font-size: 15px;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate3d(0, -5%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
